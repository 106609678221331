import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MessageService } from 'primeng/api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { TranslocoRootModule } from './core/i18n/transloco-root.module';
import { LayoutModule } from './layout/layout.module';

import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { AuthInterceptor } from './auth/interceptors/auth.interceptor';
import { BaseUrlInterceptor } from './core/interceptors/base-url.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';

import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { NgxImageCompressService } from 'ngx-image-compress';
import { environment } from 'src/environments/environments';
import { LoaderInterceptor } from './core/loader/loader.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    CoreModule,
    TranslocoRootModule,
    HttpClientModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [
    MessageService,
    {
      provide: 'environment',
      useValue: environment,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },

    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    NgxImageCompressService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
