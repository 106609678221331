import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

// import { ErrorService } from '../services/error.service';
// import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    // private _errorService: ErrorService,
    // private _authService: AuthService,
    private _router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401 || err.status == 403) {
          // this._errorService.authErrorSubject.next(err);
          // this._authService.logout();
          // this._router.navigate(['login']);
        } else if (err.status == 0 || err?.error?.error?.code?.length) {
          // this._errorService.failedErrorSubject.next(err);
        }

        return throwError(() => err);
      })
    );
  }
}
