import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { EncryptService } from '../utils/encrypt.service';
import { ApiResponse } from '../../models/api';
import {
  ChangePasswordContrainer, LoggedUserInfo,

} from '../../models/auth';
import { tap } from 'rxjs';
import { RootRoutes } from 'src/app/app-routing.module';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _baseUrl = 'authentication';

  constructor(
    private _http: HttpClient,
    private _encryptService: EncryptService,
    private _router: Router
  ) { }

  set token(token: string) {
    localStorage.setItem('token', token);
  }

  get token(): string {
    return localStorage.getItem('token') ?? '';
  }

  set userInfo(userInfo: LoggedUserInfo) {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }

  get userInfo() {
    return JSON.parse(localStorage.getItem('userInfo') || '');
  }

  public logout(): void {
    localStorage.clear();
    this._router.navigate([RootRoutes.
      APP, RootRoutes.LOGIN])
  }

  public login(email: string, password: string) {
    return this._http
      .post<LoggedUserInfo>(`${this._baseUrl}/authenticate`, {
        email,
        password: this._encryptService.encrypt(password),
      })
      .pipe(
        tap((response) => {
          if (response.token) {
            this.token = response.token;
            this.userInfo = response!
          }
        })
      );
  }

  public registration(email: string, password: string) {
    return this._http.post<ApiResponse<any>>(`${this._baseUrl}/registration`, {
      email,
      password: this._encryptService.encrypt(password),
    });
  }

  public verifyUser(email: string, verificationCode: string) {
    return this._http.post(`${this._baseUrl}/VerifyUser`, {
      email,
      verificationCode,
    });
  }

  public sendVerificationCode(email: string) {
    return this._http.get(`${this._baseUrl}/ResendVerificationCode/${email}`);
  }

  public resetPassword(email: string) {
    return this._http.get(`${this._baseUrl}/ResetPassword/${email}`);
  }

  public changePassword(info: ChangePasswordContrainer) {
    info.newPassword = this._encryptService.encrypt(info.newPassword);
    info.oldPassword = this._encryptService.encrypt(info.oldPassword);

    return this._http.post(`${this._baseUrl}/ChangePassword`, {
      ...info,
    });
  }

  public refreshToken(oldToken: string) {
    return this._http
      .post(`${this._baseUrl}/RefreshToken`, {
        oldToken,
      })
      .pipe(
        tap((response: any) => {
          if (response.token) {
            this.token = response.token;
          }
        })
      );
  }
}
