import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { SidebarModule } from 'primeng/sidebar';
import { TreeModule } from 'primeng/tree';

import { AppDefaultLayoutComponent } from './components/default-layout/app-default-layout.component';
import { AppEmptyLayoutComponent } from './components/empty-layout/app-empty-layout.component';
import { AppTopBarComponent } from './components/topbar/app-top-bar.component';
import { ButtonModule } from 'primeng/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from 'primeng/api';
import { AvoidEmptyValuePipe } from '../shared/pipes/avoid-empty-value.pipe';
import { OverlayModule } from 'primeng/overlay';
@NgModule({
  declarations: [
    AppEmptyLayoutComponent,
    AppDefaultLayoutComponent,
    AppTopBarComponent,
  ],
  imports: [
    FontAwesomeModule,
    SharedModule,
    TranslocoModule,
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    ToolbarModule,
    ToastModule,
    ButtonModule,
    OverlayModule,
    SidebarModule,
    TreeModule,
  ],
})
export class LayoutModule {}
