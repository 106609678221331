import { Inject, Injectable } from '@angular/core';
import { JSEncrypt } from "jsencrypt";
import { Environment } from "../../../core/models/environment";

@Injectable({
  providedIn: 'root'
})
export class EncryptService {

  private _encrypt: JSEncrypt;

  constructor(@Inject("environment") environment: Environment) {
    this._encrypt = new JSEncrypt();
    this._encrypt.setPublicKey(environment.apiPublicKey);
  }

  public encrypt(plaintext: string): string {
    return this._encrypt.encrypt(plaintext).toString();
  }
}
