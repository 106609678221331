import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';
import { RootRoutes } from 'src/app/app-routing.module';
import { AuthService } from '../../shared/services/api/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService, private _router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const idToken = this._authService.token;

    const cloned = req.clone({
      headers: idToken
        ? req.headers.set('Authorization', 'Bearer ' + idToken)
        : req.headers,
    });

    return next.handle(cloned).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          // auto logout if 401 or 403 response returned from api
          this._authService.logout();
          this._router.navigateByUrl(RootRoutes.LOGIN);
        }
        return throwError(() => error);
      })
    );
  }
}
